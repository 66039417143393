
import Vue from '@/vueTyped';
import LocationAutocomplete from '@/components/location/LocationAutocomplete.vue';
import BaseCustomInput from '@/components/BaseCustomInput.vue';
import MapInput from '@/components/location/MapInput.vue';
import { reverseGeocode } from '@/util.mapbox';
import { MBAddressObj } from '@/types';


export default Vue.extend({
    name: 'TwoStepAddressSelector',

    components: {
        LocationAutocomplete,
        BaseCustomInput,
        MapInput,
    },

    props: {
        locationLabel: {
            type: String,
            default: '',
        },

        location: {
            type: Object as () => MBAddressObj | null,
            default: () => null
        },

        locationHint: {
            type: String,
            default: ''
        },

        coordinatesLabel: {
            type: [Boolean, String],
            default: true,
        },

        coordinates: {
            type: Array as unknown as () => [number, number],
            default: () => null,
        },
    },

    data() {
        return {
            errors: {
                location: null as string | null,
                coordinates: null as string | null,
            },
        };
    },

    computed: {
        locationIsAddressable(this: any) {
            const addressableTypes = ['address', 'poi'];
            return this.location?.place_type.some((name: string) => addressableTypes.includes(name));
        }
    },

    watch: {
        async coordinates(this: any, coordinates) {
            this.errors.coordinates = null;
            if (coordinates && coordinates.join() !== this.location?.center.join()) {
                const reverseGeolocation = await reverseGeocode(coordinates, { limit: 1 });
                if (reverseGeolocation.features.length !== 0) {
                    const feature = reverseGeolocation.features[0];
                    this.$emit('update:location', feature);
                }
            }
        }
    },

    methods: {
        async setLocationFromAutocomplete(this: any, location: MBAddressObj) {
            this.errors.location = null;
            this.errors.coordinates = null;

            this.$emit('update:location', location);
            await this.$nextTick();
            this.$emit('update:coordinates', this.locationIsAddressable ? location.center : null);

            if (location) {
                await this.$nextTick();
                this.$refs.mapInput.focus();
            }
        },

        validate() {
            if (!this.location) {
                this.errors.location = this.$t('locationSelection.setStartPoint') as string;
            }

            if (!this.coordinates) {
                this.errors.coordinates = this.$t('locationSelection.setCoordinates') as string;
            }
        },
    },
});
